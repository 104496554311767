<!-- Props to add:
  1. selectedDiffGrp - For selected difficulty group
  2. Difficulty - For difficulty group
  3. QuestionDiagramURL - to display Question
  4. isQustionSelected - to display checked on selected question -->
<template>
  <div>
    <div class="ques-box flex" :class="{ disable: SelectedQns }">
      <!-- <div class="ques-checkbox relative" :class="setDiffClass(selectedDiffGrp)" :style="showCheckbox ? 'display: block' : 'display: none'" style="max-width: 39px; height: 39px;">
      </div> -->
      <div class="ques-img relative pointer" :class="[Difficulty, { 'latext-img': isLatex === 1 }]">
        <img
          :src="QuestionDiagramURL"
          alt=""
          class="h-100 w-100"
          style="border-radius: 5px"
          v-if="isLatex === 0"
          draggable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      QuestionDiagramURL: "",
      isLatex: 0,
      LatexUrl: "",
    };
  },
  props: {
    Difficulty: String,
    selectedDiffGrp: String,
    QuestionDetail: Object,
    isQustionSelected: Boolean,
    showReplaceBtn: {
      type: Boolean,
      default: false,
    },
    SelectedQns: {
      type: Boolean,
      default: false,
    },
    showAddedText: {
      type: Boolean,
      default: false,
    },
    showAddBtn: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    qnsData: Object,
  },
  mounted() {
    this.QuestionDiagramURL = this.QuestionDetail.QuestionDiagramURL;
  },
  methods: {
    setDiffClass(diffClass) {
      if (diffClass === "basic" && this.isQustionSelected) {
        return "basic";
      }
      if (diffClass === "easy" && this.isQustionSelected) {
        return "easy";
      }
      if (diffClass === "normal" && this.isQustionSelected) {
        return "normal";
      }
      if (diffClass === "hard" && this.isQustionSelected) {
        return "hard";
      }
      if (diffClass === "tough" && this.isQustionSelected) {
        return "tough";
      }
      return "";
    },
    replaceQns(qnsData) {
      this.$emit("replaceBtnTriggered", qnsData);
    },
  },
};
</script>

<style scoped>
.skyblue-text {
  color: #3751ff;
}
.dark-tex {
  color: #333333;
}
.ques-box {
  /* max-width: 700px; */
  margin-bottom: 30px;
}
.ques-box.disable {
  pointer-events: none;
  cursor: none;
}
.ques-checkbox {
  max-width: 35px;
  width: 100%;
  height: 35px;
  background: white;
  border-radius: 50%;
  margin-right: 13px;
  border: 1px solid #e0e4f0;
  cursor: pointer;
}
.ques-img {
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  max-width: 800px;
  min-width: 300px;
  width: 100%;
}
.ques-img.latext-img {
  background: white;
  padding: 5px 10px 10px 10px;
}
.ques-checkbox.basic {
  background: #16a085;
}
.ques-img.basic::before,
.ques-img.latext-img.basic:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #16a085;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.basic:before {
  left: -2px !important;
}
.ques-checkbox.easy {
  background: #8bc32e;
}
.ques-img.easy::before,
.ques-img.latext-img.easy:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #8bc32e;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.easy:before {
  left: -2px !important;
}

.ques-checkbox.hard {
  background: #ff835ce5;
}
.ques-checkbox::before {
  position: absolute;
  left: 6px;
  top: 42%;
  height: 14px;
  width: 5px;
  border-radius: 5px;
  background-color: #fff;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.ques-checkbox::after {
  position: absolute;
  left: 1px;
  bottom: 8px;
  height: 5px;
  width: 21px;
  border-radius: 4px;
  background-color: #fff;
  content: "";
  transform: translateX(15px) rotate(-45deg);
  transform-origin: left bottom;
}

.ques-img.hard::before,
.ques-img.latext-img.hard:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #ff835ce5;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.hard:before {
  left: -2px !important;
}
.ques-checkbox.normal {
  background: #ffbf44;
}
.ques-img.normal::before,
.ques-img.latext-img.normal:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #ffbf44;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.normal:before {
  left: -2px !important;
}
.ques-checkbox.tough {
  background: #b03e3e;
}
.ques-img.tough::before,
.ques-img.latext-img.tough:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #b03e3e;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.tough:before {
  left: -2px !important;
}
.green-btn {
  bottom: 0;
  right: 5px;
  width: 100px;
  height: 28px;
  background: #37841c;
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 28px;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.ques-img:hover .green-btn {
  opacity: 1;
}
.option {
  max-width: 450px;
  margin: 20px 0;
}
</style>
