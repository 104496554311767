<template>
  <div class="main">
    <div class="header-filter">
      <div class="flex" style="gap: 30px">
        <button
          class="filter-btn fw-600 fs-12"
          v-for="(btn, index) in btnList"
          :key="index"
          :class="{ 'blue-bg': btn.isSelected, 'white-font': btn.isSelected }"
          @click="changeStatus(), assignmentStatusHandler(index, btn.id)"
        >
          {{ btn.name }}
        </button>
      </div>
      <div>
        <button class="base-btn orange-gradient fs-14" @click="createAssignNav">
          Create Assignment
        </button>
      </div>
    </div>
    <div class="assignment-filters">
      <div>
        <select v-model="selectedCourse" @change="onCourseFilter" class="filter-dropdown">
          <option :selected="selectedCourse === 0" value="0">All</option>
          <option
            v-for="(course, index) in allCoursesToFilter"
            :value="course.CourseId"
            :key="index"
          >
            {{ course.CourseName }}
          </option>
        </select>
      </div>
      <div>
        <select v-model="selectedFilterBatch" @change="onBatchFilter" class="filter-dropdown">
          <option :selected="selectedFilterBatch === 0" value="0">All</option>
          <option v-for="(batch, index) in batchList" :value="batch.BatchId" :key="index">
            {{ batch.BatchName }}
          </option>
        </select>
      </div>
    </div>
    <div class="assignment-list scroll-verticle-bar" style="max-height: calc(100vh - 268px)">
      <div v-if="assignmentListAfterFilter.length > 0">
        <div v-for="(assignment, index) in assignmentListAfterFilter" :key="index">
          <div class="assignment-item">
            <div>
              <p class="lps-title fw-600 fs-14 truncate" :title="assignment.Title">
                {{ assignment.Title }}
              </p>
              <p class="fs-12">{{ assignment.CourseName }}</p>
              <p class="fs-12">{{ assignment.Subject }}</p>
            </div>
            <div>
              <p class="fw-600 fs-14">Batches</p>
              <p class="fs-12 space-break" :title="assignment.Batches">
                {{ textEllipsis(assignment.Batches, 50) || "" }}
              </p>
            </div>
            <div>
              <p class="fw-600 fs-14">Assignment Type</p>
              <p class="fs-12">{{ assignment.AssignmentType }}</p>
              <div v-if="assignmentStatusId !== 1">
                <p class="fw-600 fs-14">Professor</p>
                <p class="fs-12">{{ assignment.Faculty }}</p>
              </div>
            </div>
            <div v-if="assignmentStatusId === 1">
              <p class="fw-600 fs-14">Professor</p>
              <p class="fs-12">{{ assignment.Faculty }}</p>
            </div>
            <div v-else>
              <p class="fs-14 fw-600">Start:</p>
              <p class="fs-12">{{ formatDateTime(assignment.StartDate) || "" }}</p>
              <p class="fs-14 fw-600">End:</p>
              <p class="fs-12">{{ formatDateTime(assignment.EndDate) || "" }}</p>
            </div>
            <div>
              <button
                v-if="!assignment.IsPublished && assignment.AssignmentType !== 'Auto Assignment'"
                @click="openPublishModal(assignment.AssignmentLogId)"
                class="action-btn orange-gradient"
              >
                Publish
              </button>
              <button
                v-if="
                  assignment.IsPublished &&
                  currentDateTimeISO < assignment.StartDate &&
                  assignment.AssignmentType !== 'Auto Assignment'
                "
                @click="openRescheduleModal(assignment.AssignmentLogId)"
                class="action-btn blue-bg"
              >
                Reschedule
              </button>
              <button
                v-if="
                  assignment.IsPublished &&
                  assignment.StartDate <= currentDateTimeISO &&
                  assignment.AssignmentType !== 'Auto Assignment'
                "
                class="action-btn silver-bg cursor-none"
              >
                Published
              </button>
              <button
                v-if="assignment.AssignmentType === 'Auto Assignment'"
                class="action-btn silver-bg cursor-none"
              >
                Published
              </button>
            </div>
            <div>
              <div class="flex items-center" style="gap: 20px">
                <img
                  v-if="assignment.AssignmentType !== 'Auto Assignment'"
                  @click="onViewAssignment(assignment.AssignmentLogId)"
                  :src="require(`../assets/eye-icon.svg`)"
                  alt=""
                  class="eyeIcon"
                />
                <span
                  class="material-icons cursor-pointer"
                  v-if="!assignment.IsPublished && assignment.AssignmentType !== 'Auto Assignment'"
                  style="color: red; font-size: 26px; display: inline-block"
                  @click="deleteModal(assignment.AssignmentLogId)"
                  >cancel</span
                >
                <img
                  @click="onCopyAssignment(assignment.AssignmentLogId)"
                  :src="require(`../assets/copy.svg`)"
                  alt=""
                  class="eyeIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-center" style="margin-bottom: 20px">
          <img
            :src="require(`../assets/no-result-found.png`)"
            alt=""
            style="width: 200px; opacity: 0.7"
          />
          <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">
            No assignment available.
          </p>
        </div>
      </div>
    </div>
    <div class="pagination flex flex-between">
      <div>
        <p class="show-index fw-500 fs-15">
          <span>Showing </span>
          <span>{{ startPageNumber }}</span>
          <span> - </span>
          <span>{{ endPageNumber }}</span>
          <span> of </span>
          <span>{{ this.totalAssigment ? this.totalAssigment : 0 }}</span>
          <span> Tests</span>
        </p>
      </div>
      <div class="flex flex-wrap">
        <a
          @click="prevPage()"
          :class="{ disable: currentPage < 2 }"
          class="navigation-btn prev-btn"
          title="Previous"
        >
          <span class="material-icons material-symbols-outlined fs-20 pointer">west</span>
        </a>
        <a
          @click="nextPage()"
          :class="{ disable: listLength < 20 || endPageNumber === this.totalAssigment }"
          class="navigation-btn"
          title="Next"
        >
          <span class="material-icons material-symbols-outlined fs-20 pointer">east</span>
        </a>
      </div>
    </div>
    <Modal3
      :show="isPublishModalVisible"
      @close="isPublishModalVisible = false"
      :showHeader="false"
      :showCloseBtn="false"
      :width="'100%'"
      :height="'100%'"
      :maxHeightBody="'800px'"
    >
      <template v-slot:body>
        <div class="concept-modal">
          <div class="flex flex-between publish-head">
            <span class="fs-16 fw-700" style="margin-top: 2px">Publish Assignment</span>
            <div @click="isPublishModalVisible = false" style="cursor: pointer">
              <span class="material-icons publish-close pointer" style="color: #ffffff !important"
                >close</span
              >
            </div>
          </div>
          <div class="modal-content">
            <div class="row">
              <div class="col s6">
                <span class="fs-14 fw-700">Start DateTime</span>
                <v-date-picker
                  v-model="startDate"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-input"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
              <div class="col s6">
                <span class="fs-14 fw-700">End Date Time</span>
                <v-date-picker
                  v-model="endDate"
                  mode="dateTime"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-input"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
                <p v-if="dateValidation" style="color: red" class="fs-10 fw-700">
                  End date cannot be less than start date.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <span class="fs-14 fw-700">Students</span>
                <treeselect
                  @select="selectBatch($event)"
                  style="display: flex"
                  :showCount="true"
                  @deselect="unselectBatch($event)"
                  :multiple="true"
                  :options="newStudentList"
                  :openDirection="bottom"
                  :max-height="300"
                  :limit="2"
                  placeholder="Select students."
                >
                  <template v-slot:option-label="{ node, shouldShowCount, count }">
                    <label style="font-size: 16px; font-weight: 600; color: black">
                      {{ node.label }}
                      <span
                        v-if="shouldShowCount"
                        style="font-size: 16px; font-weight: 600; color: black"
                        >({{ count }})</span
                      >
                    </label>
                  </template>
                  <template v-slot:value-label="{ node }">
                    <label style="font-size: 16px; font-weight: 600; color: black">
                      {{ node.label }}
                    </label>
                  </template>
                </treeselect>
                <p v-if="studentValidation" style="color: red">Please select Students.</p>
              </div>
            </div>
            <div class="row" style="padding-top: 10px; margin-top: 100px">
              <div class="col s12">
                <button @click="publishTheAssignment()" class="publish-modal-button">
                  Publish
                </button>
                <button @click="cancelPublish()" class="cancel-publish-btn">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal3>
    <Modal
      :show="isRescheduleAssigmentVisible"
      @close="isRescheduleAssigmentVisible = false"
      :showHeader="false"
      :showCloseBtn="true"
    >
      <template v-slot:body>
        <div class="reschedule-modal">
          <div class="viewQuestionHeader">
            <p class="fs-20 fw-600">Reschedule Assignment</p>
          </div>
          <div class="flex flex-between flex-wrap">
            <div>
              <label class="fs-14 fw-600 dark-font">Start DateTime</label>
              <v-date-picker
                v-model="startDate"
                mode="dateTime"
                :min-date="minDate"
                :minute-increment="5"
                is-required
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    class="browser-default"
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly
                  />
                </template>
              </v-date-picker>
            </div>
            <div>
              <label class="fs-14 fw-600 dark-font">End DateTime</label>
              <v-date-picker
                v-model="endDate"
                mode="dateTime"
                :min-date="minDate"
                :max-date="maxDate"
                :minute-increment="5"
                is-required
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    class="browser-default"
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly
                  />
                </template>
              </v-date-picker>
            </div>
          </div>
          <p v-if="dateValidation" style="color: red" class="fs-12px">
            End date cannot be less than start date.
          </p>
          <div class="modal-btns">
            <button @click="cancelPublish()" class="modal-btn no-btn silver-bg">Cancel</button>
            <button @click="rescheduleAssignment()" class="modal-btn blue-bg" style="width: 100px">
              Reschedule
            </button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal
      :show="isQuestionAssignemntVisible"
      @close="isQuestionAssignemntVisible = false"
      :showHeader="false"
      :showCloseBtn="false"
      :width="'100%'"
      :height="'100%'"
      :maxHeightBody="'800px'"
    >
      <template v-slot:body>
        <div v-if="this.pdfURL.length > 0">
          <div class="viewQuestionHeader">
            <p class="fs-20 fw-600">View the Uploaded Assignment</p>
            <div class="flex flex-between">
              <img
                :src="require(`../assets/modalClose.svg`)"
                style="cursor: pointer"
                @click="isQuestionAssignemntVisible = false"
              />
            </div>
          </div>
          <br />
          <iframe
            width="100%"
            style="height: 600px"
            :src="`data:application/pdf;base64,${encodeURI(fileContent)}`"
          ></iframe>
        </div>
        <div v-if="this.pdfURL.length === 0">
          <div class="viewQuestionHeader">
            <p class="fs-20 fw-600">View Questions</p>
            <div class="flex align-items" style="gap: 20px">
              <p class="fs-20 fw-600">Selected Questions: {{ questionURL.length }}</p>
              <div class="flex flex-between">
                <img
                  :src="require(`../assets/modalClose.svg`)"
                  style="cursor: pointer"
                  @click="isQuestionAssignemntVisible = false"
                />
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 30px">
            <div class="col s12 l2">
              <div class="commonFilterComponent">
                <CommonFilterComponent
                  :entityDataObject="this.conceptObject"
                  :isCluster="true"
                  :isChapter="false"
                  @selectedEntityId="getSelectedEntityId($event)"
                />
              </div>
              <DifficultLevelComponent
                :selectedClusterIdQuestionslist="selectedClusterIdQuestions"
                @selectedDiffLevel="getCommonQDiffLevel($event)"
              />
            </div>
            <div class="col s12 l10">
              <div
                style="text-align: center; max-width: 850px; width: 100%"
                class="flex items-center"
                v-if="questionURL.length === 0"
              >
                <span
                  class="material-icons material-symbols-outlined red-text"
                  style="font-size: 60px"
                  >error</span
                >
                <p
                  class="fs-18 red-text fw-600"
                  style="margin-left: 10px !important; text-align: left"
                  v-if="errorMsg !== ''"
                >
                  {{ errorMsg }}
                </p>
              </div>
              <div class="scroll-verticle-bar" v-if="questionURL.length > 0">
                <AssignmentQuestion
                  v-for="item in questionURL"
                  :key="item.QuestionId"
                  :selectedDiffGrp="
                    item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                  "
                  :Difficulty="item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'"
                  :QuestionDetail="item"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :show="isDeleteModalVisible"
      @close="isDeleteModalVisible = false"
      :showHeader="false"
      :showCloseBtn="true"
      style="overflow-y: none !important"
    >
      <template v-slot:body>
        <div class="cancel-modal">
          <span class="material-icons" style="font-size: 50px !important; color: #ff6b6b"
            >delete_forever</span
          >
          <p class="fs-26 fw-700 dark-font">Delete?</p>
          <p class="fs-16 fw-400 dark-font opacity-low">
            Do you really want to <b>delete</b> this assignment?<br />This process cannot be undone.
          </p>
          <div class="modal-btns">
            <button @click="cancelPublish()" class="modal-btn no-btn silver-bg">No</button>
            <button
              @click="deleteAssignment(deleteAssignId)"
              class="modal-btn yes-btn"
              style="width: 100px"
            >
              Yes
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { useToast } from "vue-toastification";
import Treeselect from "vue3-treeselect";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";
import AssignmentQuestion from "../components/AssignmentQuestion.vue";
import DifficultLevelComponent from "../components/DifficultLevelComponent";
import CommonFilterComponent from "../components/CommonFilterComponent.vue";
import Modal3 from "../components/Modal3.vue";

const toast = useToast();
export default {
  components: {
    Modal,
    DifficultLevelComponent,
    CommonFilterComponent,
    AssignmentQuestion,
    Modal3,
    Treeselect,
  },
  data() {
    return {
      btnList: [
        {
          id: 1,
          name: "Pending",
          isSelected: true,
        },
        {
          id: 2,
          name: "Published",
          isSelected: false,
        },
      ],
      assignmentIndex: 0,
      assignmentStatusId: null,
      assignmentlist: [],
      filteredAssignmentlist: [],
      assignmentListAfterFilter: [],
      momentData: moment,
      assignmentlogId: "",
      publishAssignment: [],
      pdfURL: "",
      fileContent: "",
      isQuestionAssignemntVisible: false,
      questionURL: [],
      isDeleteModalVisible: false,
      deleteAssignId: null,
      isRescheduleAssigmentVisible: false,
      minDate: moment().startOf("day").toDate(),
      maxDate: moment().add(3, "months").toDate(),
      startDate: moment().add(15, "minutes").toDate(),
      endDate: moment().add(30, "minutes").toDate(),
      assstartDate: "",
      assendDate: "",
      userDetails: [],
      isPublishModalVisible: false,
      startPageNumber: 0,
      endPageNumber: 20,
      currentPage: 1,
      currentPublishedPage: 1,
      currentPendingPage: 1,
      listLength: 0,
      totalAssigment: 0,
      totalCount: 0,
      next: true,
      changedPage: false,
      statusChanged: false,
      isPublished: false,
      updateQuesNo: false,

      //   publishModal
      dateValidation: false,
      selectClass: null,
      batchValidation: false,
      studentValidation: false,
      studentId: "",
      selectBatchId: null,
      batchStudentIdCsv: [],
      dataObj: {},
      studentCsv: [],
      studentList: [],
      selectedBatch: [],

      // course-batch filters
      allCoursesToFilter: [],
      allBatchesToFilter: [],
      selectedFilterBatch: 0,
      selectedCourse: 0,

      // modal filters
      uniqueClusterArray: [],
      selctedClusterList: [],
      selectedCommonQuestionCluster: [],
      commonAssignmentKscQList: [],
      diffLevelFilterByClusterId: [],

      commonQsDifficultyList: [],
      conceptObject: {},
      errorMsg: "",

      // unique batch and students
      studentIdList: "",
      allselectedBatchStudentList: [],
      selectedBatchStudentArray: [],
      batchList: [],
      newBatchList: [],
      batchIds: [],
      counter: 0,
      newStudentList: [],
      studentIds: [],
      selectedBatchIds: [],
      selectedStudentsId: [],
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  watch: {
    startDate(newVal) {
      this.assstartDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
      this.dateValidation = false;
    },
    endDate(newVal) {
      this.assendDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
      this.dateValidation = false;
    },
  },
  mounted() {
    this.userDetails = this.$store.getters.user;
    this.changedPage = true;
    this.assignmentStatusHandler(0, 1);
    this.startPageNumber = 1 + (this.currentPage - 1) * 20;
    this.endPageNumber = this.currentPage * 20;
  },
  computed: {
    currentDateTimeISO() {
      return moment().add(330, "minutes").toISOString();
    },
    selectedClusterIdQuestions() {
      return this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
    },
    uniqueBatchIds() {
      return [
        ...new Set(
          this.batchStudentIdCsv
            .map((e) => e.StudentIdCsv)
            .join(",")
            .split(","),
        ),
      ].toString();
    },
  },
  methods: {
    nextPage() {
      this.changedPage = true;
      this.next = true;
      this.currentPage += 1;
      this.endPageNumber = this.currentPage * 20;
      this.assignmentStatusHandler(this.assignmentIndex, this.assignmentStatusId);
      this.startPageNumber = 1 + (this.currentPage - 1) * 20;
    },
    prevPage() {
      this.changedPage = true;
      this.next = false;
      this.endPageNumber -= this.listLength;
      this.currentPage -= 1;
      if (this.updateQuesNo) {
        this.endPageNumber = this.currentPage * 20;
      }
      this.assignmentStatusHandler(this.assignmentIndex, this.assignmentStatusId);
      this.startPageNumber = 1 + (this.currentPage - 1) * 20;
      if (this.startPageNumber === 0) {
        this.startPageNumber = 1;
      }
      this.updateQuesNo = false;
    },
    // modal filters
    getSelectedEntityId(selectedentityId) {
      this.selctedClusterList = selectedentityId;
      this.selectedCommonQuestionCluster = selectedentityId;
      this.questionURL = this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
      if (this.questionURL.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
      this.diffLevelFilterByClusterId = this.commonAssignmentKscQList.filter((item1) =>
        this.selectedCommonQuestionCluster.some((item2) => item2 === item1.KSCClusterId),
      );
    },
    getCommonQDiffLevel(diffleveldata) {
      this.commonQsDifficultyList = diffleveldata;
      this.democommonQsDifficultyList = diffleveldata;
      this.questionURL = this.diffLevelFilterByClusterId.filter((item1) =>
        this.commonQsDifficultyList.some(
          (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
        ),
      );
      if (this.questionURL.length === 0) {
        this.errorMsg = "No questions available for the selected filters.";
      } else {
        this.errorMsg = "";
      }
    },
    // Utility Methods/Functions
    textEllipsis(input, num) {
      if (input && input.length > num && input) {
        return `${input.substring(0, num)}...`;
      }
      return input;
    },
    formatDateTime(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("lll");
      }
      return value;
    },
    // batch-course filters
    onBatchFilter(event) {
      this.selectedFilterBatch = event.target.value;
      this.filterAssignment(this.selectedFilterBatch, this.selectedCourse);
    },
    onCourseFilter(event) {
      this.selectedCourse = event.target.value;
      if (Number(event.target.value) !== 0) {
        this.batchList = this.allBatchesToFilter.filter(
          (item) => item.CourseId === Number(event.target.value),
        );
      } else {
        this.batchList = this.allBatchesToFilter;
      }
      this.filterAssignment(this.selectedFilterBatch, this.selectedCourse);
    },

    filterAssignment(selectBatch, selectCourse) {
      this.updateQuesNo = true;
      let selectedAssignment = this.filteredAssignmentlist;
      if (Number(selectBatch) !== 0) {
        selectedAssignment = selectedAssignment.filter((item) =>
          item.BatchDetails.some((e) => Number(e.BatchId) === Number(selectBatch)),
        );
      }
      if (Number(selectCourse) !== 0) {
        selectedAssignment = selectedAssignment.filter(
          (item) => Number(item.CourseId) === Number(selectCourse),
        );
      }
      this.assignmentListAfterFilter = selectedAssignment;
      this.assignmentListAfterFilter = this.assignmentListAfterFilter.sort(
        (a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn),
      );
      if (selectCourse === 0 && selectBatch === 0) {
        this.startPageNumber = 1 + (this.currentPage - 1) * 20;
        this.endPageNumber = this.assignmentListAfterFilter.length + (this.currentPage - 1) * 20;
      } else if (this.assignmentListAfterFilter.length === 0) {
        this.startPageNumber = 0;
        this.endPageNumber = 0;
      } else {
        this.startPageNumber = 1;
        this.endPageNumber = this.assignmentListAfterFilter.length;
      }
    },
    createAssignNav() {
      this.$router.push({ name: "CreateAssignment" });
    },
    changeStatus() {
      this.statusChanged = true;
      this.currentPage = 1;
      this.totalCount = 0;
      this.startPageNumber = 1 + (this.currentPage - 1) * 20;
      this.endPageNumber = this.currentPage * 20;
    },
    getAssignmentListData() {
      if (!this.next && this.changedPage) {
        this.totalCount -= this.listLength;
      }
      MobileAPI.getAssignmentList(
        {
          FacultyId: this.userDetails.UserId,
          Published: this.assignmentIndex,
          PageNo: this.currentPage,
        },
        (response) => {
          if (response.responseCode === 200) {
            if (Object.keys(response.data).length !== 0 && response.data !== null) {
              this.assignmentlist = response.data || [];
              this.filteredAssignmentlist = this.assignmentlist;
              this.$store.dispatch("showLoader", false);
              this.assignmentListAfterFilter = this.filteredAssignmentlist;
              this.assignmentListAfterFilter = this.assignmentListAfterFilter.sort(
                (a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn),
              );
              this.totalAssigment = this.assignmentListAfterFilter[0].TotalAssigment;
              this.createCourseBatchFilter();
              if (this.isPublished) {
                this.startPageNumber = 1 + (this.currentPage - 1) * 20;
                this.endPageNumber =
                  this.assignmentListAfterFilter.length + (this.currentPage - 1) * 20;
                if (this.totalCount > this.totalAssigment) {
                  this.totalCount -= this.totalCount - this.totalAssigment;
                }
              }
              if (this.next && this.changedPage) {
                this.totalCount += this.assignmentListAfterFilter.length;
              }
              if (this.statusChanged) {
                this.totalCount += this.assignmentListAfterFilter.length;
              }
              if (this.totalCount === this.totalAssigment) {
                this.endPageNumber = this.totalCount;
              }
              this.listLength = this.assignmentListAfterFilter.length;
              this.changedPage = false;
              this.statusChanged = false;
              this.isPublished = false;
            }
            this.$store.dispatch("showLoader", false);
          } else {
            this.$store.dispatch("showLoader", false);
            toast.error(`${response.data.info}`, {
              timeout: 2500,
            });
          }
        },
      );
    },
    createCourseBatchFilter() {
      const allBatches = this.filteredAssignmentlist
        .map((item) => item.BatchDetails)
        .flat()
        .map((e) => ({
          BatchId: e.BatchId,
          BatchName: e.Name,
          CourseId: e.CourseId,
        }));
      this.allBatchesToFilter = [
        ...new Map(allBatches.map((item) => [item.BatchId, item])).values(),
      ];
      const allCourses = this.filteredAssignmentlist.map((item) => ({
        CourseId: item.CourseId,
        CourseName: item.CourseName,
      }));
      this.allCoursesToFilter = [
        ...new Map(allCourses.map((item) => [item.CourseId, item])).values(),
      ];
    },
    assignmentStatusHandler(index, btnId) {
      this.assignmentIndex = index;
      this.assignmentStatusId = btnId;
      this.selectedFilterBatch = 0;
      this.selectedCourse = 0;
      this.btnList.forEach((e) => {
        e.isSelected = false;
      });
      this.btnList[index].isSelected = true;
      this.$store.dispatch("showLoader", true);
      this.getAssignmentListData();
    },

    onselectedBatchChange(event) {
      this.studentId = "";
      this.dataObj = {};
      this.batchValidation = false;
      this.studentList = [];
      this.selectBatchId = Number(event.target.value);
      const user = this.$store.getters.user;
      MobileAPI.getStudentForAssignment(
        {
          AffiliationId: user.AffiliationId,
          BatchIds: this.selectBatchId,
        },
        (response) => {
          if (response.data !== null) {
            if (response.data.length === 0) {
              toast.error("No student available for selected batch");
            } else {
              // eslint-disable-next-line no-return-assign, no-param-reassign, no-sequences
              const hash = this.selectedBatch.reduce(
                (p, c) => (p[c.BatchId] ? p[c.BatchId].push(c) : (p[c.BatchId] = [c]), p),
                {},
              );
              const newData = Object.keys(hash).map((k) => ({
                id: parseInt(k),
                label: hash[k][0].BatchName,
                isDisabled: false,
                children: hash[k].map(({ UserId, Name }) => ({
                  id: UserId,
                  label: Name,
                  isDisabled: false,
                })),
              }));
              this.newStudentList = newData;
            }
          } else {
            this.$store.dispatch("showLoader", false);
            toast.error(`${response.data.info}`, {
              timeout: 2500,
            });
          }
        },
      );
      this.studentCsv = [];
    },
    studentTag(newTag) {
      const tag = {
        Name: newTag,
        UserId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.studentList.push(tag);
      this.studentCsv.push(tag);
      this.studentValidation = false;
    },
    selectStudentNone() {
      this.studentCsv = [];
    },
    selectStudentAll() {
      this.studentCsv = this.studentList;
      const student = this.studentCsv.map((a) => a.UserId);
      this.studentId = String(student);
    },
    updateSelectedStudent(value) {
      if (this.studentCsv.length >= 0) {
        this.selectedStudents = [];
      }
      value.forEach((resource) => {
        this.selectedStudents.push(resource);
      });
      const student = this.selectedStudents.map((a) => a.UserId);
      this.studentId = String(student);
      if (this.studentId !== "") {
        const data = {
          BatchId: this.selectBatchId,
          StudentIdCsv: this.studentId,
        };
        this.randomdata = data;
      }
      this.previousLength = this.selectedStudents.length;
      this.studentValidation = false;
    },
    onBatchSelect(event) {
      this.dataObj = {
        BatchId: Number(event.target.value),
        StudentIdCsv: this.studentId,
      };

      // Check if the batchId and studentId already exists in this.batchStudentIdCsv
      const batchIdStudentIdExists = this.batchStudentIdCsv.some(
        (obj) =>
          obj.BatchId === this.dataObj.BatchId && obj.StudentIdCsv === this.dataObj.StudentIdCsv,
      );

      if (
        this.dataObj.StudentIdCsv !== "" &&
        this.dataObj.BatchId !== 0 &&
        this.dataObj.BatchId !== null &&
        !batchIdStudentIdExists
      ) {
        this.batchStudentIdCsv.push(this.dataObj);
        const result = this.batchStudentIdCsv.reduce((acc, obj) => {
          const existingObj = acc.find((r) => r.BatchId === obj.BatchId);
          if (existingObj) {
            existingObj.StudentIdCsv += `,${obj.StudentIdCsv}`;
          } else {
            acc.push(obj);
          }
          return acc;
        }, []);

        const finalResult = result.map((obj) => {
          const uniqueStudentIds = [...new Set(obj.StudentIdCsv.split(","))];
          return {
            BatchId: obj.BatchId,
            StudentIdCsv: uniqueStudentIds.join(","),
          };
        });
        this.batchStudentIdCsv = finalResult;
        this.dataObj = {};
        this.selectBatchId = null;
        this.batchValidation = false;
      }
      this.dataObj = {};
    },
    publishTheAssignment() {
      if (this.publishAssignment[0].AssignmentType === "File") {
        if (
          moment(moment(this.assendDate).toISOString()).isBefore(
            moment(this.assstartDate).toISOString(),
          )
        ) {
          this.dateValidation = true;
          return;
        }
        if (this.selectClass === null) {
          this.batchValidation = true;
          return;
        }
        if (this.selectedBatchIds.length === 0 && this.selectedStudentsId.length === 0) {
          this.studentValidation = true;
          return;
        }
        if (this.studentId !== "") {
          this.dataObj = {
            BatchId: this.selectBatchId,
            StudentIdCsv: this.studentId,
          };
          if (
            this.dataObj.StudentIdCsv !== "" &&
            this.dataObj.BatchId !== 0 &&
            this.dataObj.BatchId !== null
          ) {
            this.batchStudentIdCsv.push(this.dataObj);
            this.dataObj = {};
            this.selectBatchId = null;
          }
        }
        const data = {
          AssignmentLogId: this.assignmentlogId,
          PdfAssignmentId: this.publishAssignment[0].PdfAssignmentId,
          ExamTypeId: 2,
          StartDate: this.assstartDate,
          EndDate: this.assendDate,
          batchIdCsv: this.selectedBatchIds.join(","),
          studentsCsv: this.selectedStudentsId.join(","),
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.publishAssignment(data, (response) => {
          if (response.responseCode === 200) {
            this.publish = response.data;
            toast.success("Assignment published Successfully", {
              timeout: 2000,
            });
            this.studentCsv = [];
            this.selectClass = "";
            this.batchStudentIdCsv = [];
          } else {
            toast.error(`${response.data.errors[0]}`, {
              timeout: 2000,
            });
            this.studentCsv = [];
            this.selectClass = "";
            this.batchStudentIdCsv = [];
          }
          this.isPublished = true;
          this.isPublishModalVisible = false;
          this.selectedBatchIds = [];
          this.selectedStudentsId = [];
          this.assignmentStatusHandler(0, 1);
          this.newStudentList = [];
        });
      } else {
        if (
          moment(moment(this.assendDate).toISOString()).isBefore(
            moment(this.assstartDate).toISOString(),
          )
        ) {
          this.dateValidation = true;
          return;
        }
        if (this.selectClass === null) {
          this.batchValidation = true;
          return;
        }
        if (this.selectedBatchIds.length === 0 && this.selectedStudentsId.length === 0) {
          this.studentValidation = true;
          return;
        }
        if (this.studentId !== "") {
          this.dataObj = {
            BatchId: this.selectBatchId,
            StudentIdCsv: this.studentId,
          };
          if (
            this.dataObj.StudentIdCsv !== "" &&
            this.dataObj.BatchId !== 0 &&
            this.dataObj.BatchId !== null
          ) {
            this.batchStudentIdCsv.push(this.dataObj);
            this.dataObj = {};
            this.selectBatchId = null;
          }
        }
        const data = {
          AssignmentLogId: this.assignmentlogId,
          autoQuestionCsv: this.publishAssignment[0].QuestionIdsCsv,
          ExamTypeId: 2,
          StartDate: this.assstartDate,
          EndDate: this.assendDate,
          batchIdCsv: this.selectedBatchIds.join(","),
          studentsCsv: this.selectedStudentsId.join(","),
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.publishAssignment(data, (response) => {
          if (response.responseCode === 200) {
            this.publish = response.data;
            toast.success("Assignment published Successfully", {
              timeout: 2000,
            });
            this.studentCsv = [];
            this.selectClass = "";
            this.batchStudentIdCsv = [];
          } else {
            toast.error(`${response.data.info}`, {
              timeout: 2500,
            });
            this.studentCsv = [];
            this.selectClass = "";
            this.batchStudentIdCsv = [];
          }
          this.isPublishModalVisible = false;
          this.selectedBatchIds = [];
          this.selectedStudentsId = [];
          this.assignmentStatusHandler(0, 1);
          this.newStudentList = [];
        });
      }
    },
    cancelPublish() {
      this.isDeleteModalVisible = false;
      this.isRescheduleAssigmentVisible = false;
      this.isPublishModalVisible = false;
      this.studentCsv = [];
      this.selectClass = "";
    },
    openPublishModal(AssignmentLogId) {
      this.publishAssignment = this.assignmentlist.filter(
        (a) => a.AssignmentLogId === AssignmentLogId,
      );
      this.selectedBatch = this.publishAssignment[0].BatchDetails;
      // eslint-disable-next-line no-return-assign, no-param-reassign, no-sequences
      const hash = this.selectedBatch.reduce(
        (p, c) => (p[c.AffiliationId] ? p[c.AffiliationId].push(c) : (p[c.AffiliationId] = [c]), p),
        {},
      );
      const newData = Object.keys(hash).map((k) => ({
        id: parseInt(k),
        label: hash[k][0].AffiliationName,
        isDisabled: false,
        children: hash[k].map(({ BatchId, Name }) => ({
          id: BatchId,
          label: Name,
          isDisabled: false,
        })),
      }));
      this.newBatchList = newData;
      this.batchIds = "";
      this.batchIds = this.publishAssignment[0].BatchDetails.map((a) => a.BatchId).join(",");
      console.log("newBatchList", this.batchIds);
      this.counter += 1;
      this.selectedBatch = [];
      this.selectClass = "";
      this.studentCsv = [];
      this.batchStudentIdCsv = [];
      this.assignmentlogId = Number(AssignmentLogId);
      const user = this.$store.getters.user;
      MobileAPI.getStudentForAssignment(
        {
          AffiliationId: user.AffiliationId,
          BatchIds: this.batchIds,
        },
        (response) => {
          if (response.data !== null) {
            if (response.data.length === 0) {
              toast.error("No student available for selected batch");
            } else {
              // eslint-disable-next-line no-return-assign, no-param-reassign, no-sequences
              const batchHash = response.data.reduce(
                (p, c) => (p[c.BatchId] ? p[c.BatchId].push(c) : (p[c.BatchId] = [c]), p),
                {},
              );
              const batchData = Object.keys(batchHash).map((k) => ({
                id: parseInt(k),
                label: batchHash[k][0].BatchName,
                isDisabled: false,
                children: batchHash[k].map(({ UserId, Name }) => ({
                  id: UserId,
                  label: Name,
                  isDisabled: false,
                })),
              }));
              this.newStudentList = batchData;
            }
          } else {
            this.$store.dispatch("showLoader", false);
            toast.error(`${response.data.info}`, {
              timeout: 2500,
            });
          }
          this.isPublishModalVisible = true;
        },
      );
    },

    openRescheduleModal(AssignmentLogId) {
      this.isRescheduleAssigmentVisible = true;
      this.assignmentlogId = Number(AssignmentLogId);
      this.publishAssignment = this.assignmentlist.filter(
        (a) => a.AssignmentLogId === AssignmentLogId,
      );
    },
    rescheduleAssignment() {
      if (
        moment(moment(this.assendDate).toISOString()).isBefore(
          moment(this.assstartDate).toISOString(),
        )
      ) {
        this.dateValidation = true;
        return;
      }
      const data = {
        AssignmentLogId: this.assignmentlogId,
        StartDate: this.assstartDate,
        EndDate: this.assendDate,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.rescheduleAssignment(data, (response) => {
        if (response.responseCode === 200) {
          toast.success("Assignment Rescheduled", {
            timeout: 2000,
          });
        }
        this.isRescheduleAssigmentVisible = false;
        this.assignmentStatusHandler(1, 2);
        this.$store.dispatch("showLoader", false);
      });
    },

    onViewAssignment(AssignmentLogId) {
      this.commonAssignmentKscQList = [];
      this.diffLevelFilterByClusterId = [];
      this.uniqueClusterArray = [];
      this.assignmentlogId = Number(AssignmentLogId);
      this.publishAssignment = this.assignmentlist.filter(
        (a) => a.AssignmentLogId === AssignmentLogId,
      );
      const pdfId = this.publishAssignment[0].PdfAssignmentId;
      if (this.publishAssignment[0].PdfUrl) {
        MobileAPI.getAssignmentUrl(pdfId, (response) => {
          if (Object.values(response.data).length > 0) {
            this.pdfURL = response.data.FileURL;
            this.fileContent = response.data.FileContent;
            this.isQuestionAssignemntVisible = true;
          } else {
            this.$store.dispatch("showLoader", false);
            toast.error(`${response.data.info}`, {
              timeout: 2500,
            });
          }
        });
      } else {
        this.pdfURL = "";
        this.$store.dispatch("showLoader", true);
        MobileAPI.getAssignmentQuestion(
          {
            AssignmentLogId: Number(this.assignmentlogId),
          },
          (response) => {
            if (Object.values(response.data).length > 0) {
              this.questionURL = response.data;
              console.log(this.questionURL);
              const ClusterArray = this.questionURL.map((item) => ({
                entityId: item.KSCClusterId,
                entityName: item.KSCClusterName,
              }));
              this.uniqueClusterArray = [
                ...new Map(ClusterArray.map((item) => [item.entityId, item])).values(),
              ];

              this.conceptObject = {
                Name: "Concept", // Component Title
                entityArray: this.uniqueClusterArray, // array
              };
              this.commonAssignmentKscQList = this.questionURL;
              this.diffLevelFilterByClusterId = this.questionURL;
              this.isQuestionAssignemntVisible = true;
              this.$store.dispatch("showLoader", false);
            } else {
              this.$store.dispatch("showLoader", false);
              toast.error(`${response.data.info}`, {
                timeout: 2500,
              });
            }
          },
        );
      }
    },
    deleteModal(AssignmentLogId) {
      this.isDeleteModalVisible = true;
      this.deleteAssignId = AssignmentLogId;
    },
    deleteAssignment(AssignmentLogId) {
      this.$store.dispatch("showLoader", true);
      const user = this.$store.getters.user;
      MobileAPI.cancelAssignment(AssignmentLogId, (response) => {
        if (response.responseCode === 200) {
          toast.success("Assignment Canceled Successfully", {
            timeout: 2000,
          });
        }
        this.isDeleteModalVisible = false;
        MobileAPI.getAssignmentList(
          {
            FacultyId: user.UserId,
          },
          (response1) => {
            if (response1.data.length > 0) {
              this.assignmentlist = response1.data;
              this.assignmentStatusHandler(0, 1);
              this.$store.dispatch("showLoader", false);
            } else {
              this.$store.dispatch("showLoader", false);
              toast.error(`${response.data.info}`, {
                timeout: 2500,
              });
            }
          },
        );
      });
    },
    selectBatch(node) {
      if (node != null) {
        const keys = Object.keys(node);
        if (keys != null && keys.length > 0) {
          if (keys.includes("children")) {
            this.selectedBatchIds.push(node.id);
          } else {
            this.selectedStudentsId.push(node.id);
          }
        }
      }
    },
    unselectBatch(node) {
      if (node != null) {
        const keys = Object.keys(node);
        if (keys != null && keys.length > 0) {
          if (keys.includes("children")) {
            if (this.selectedBatchIds.includes(node.id)) {
              const indexOfClusterId = this.selectedBatchIds.findIndex((a) => a === node.id);
              if (indexOfClusterId >= 0) {
                this.selectedBatchIds.splice(indexOfClusterId, 1);
              }
            }
          } else if (this.selectedStudentsId.find((a) => a === node.Id)) {
            const indexOfKscId = this.selectedStudentsId.findIndex((a) => a === node.id);
            if (indexOfKscId >= 0) {
              this.selectedStudentsId.splice(indexOfKscId, 1);
            }
          }
        }
      }
    },
    onCopyAssignment(assignmentLogId) {
      MobileAPI.copyAssignment(assignmentLogId, (response) => {
        if (response.responseCode === 200 && response.data != null && Number(response.data) > 0) {
          toast.success("Assignment copied Successfully", {
            timeout: 2000,
          });
          this.getAssignmentListData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/vue3-treeselect@^0.1.1/dist/vue3-treeselect.min.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  font-family: "Open Sans", sans-serif;
}

.lps-title {
  max-width: 170px;
}

.navigation-btn {
  width: 30px;
  height: 30px;
  line-height: 38px;
  text-align: center;
  background-color: white;
  color: #8c8585;
  border: 1px solid #333333 !important;
  border-radius: 3px;
}

.navigation-btn.currentPage {
  border-color: #3751ff !important;
  color: #3751ff;
}

.navigation-btn.disable {
  pointer-events: none;
}

.prev-btn {
  margin-right: 20px;
}

.scroll-verticle,
.scroll-verticle-bar {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  white-space: nowrap !important;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle-bar::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar {
  display: block !important;
}

.scroll-verticle::-webkit-scrollbar-track,
.scroll-verticle-bar::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb,
.scroll-verticle-bar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.pagination {
  padding: 10px 14px;
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pagination {
  margin-bottom: 10px;
}

.concept-modal {
  max-width: 1173px !important;
  /* min-width: 650px !important; */
  width: 100% !important;
}

.publish-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.publish-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.modal-content {
  padding: 10px;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 27px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* -webkit-font-feature-settings: 'liga'; */
  -webkit-font-smoothing: antialiased;
  /* cursor: pointer; */
}

button {
  cursor: pointer;
}

.cursor-none {
  cursor: default !important;
}

::-webkit-scrollbar {
  display: none;
}

.card {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  background-color: #fff;
}

.dark-font {
  color: #333;
}

.darker-font {
  color: #353535;
}

.black-font {
  color: #000;
}

.white-font {
  color: #fff !important;
}

.purple-font {
  color: #642c90;
}

.blue-font {
  color: #3751ff !important;
}

.sky-blue-font {
  color: #5c8eff;
}

.bg-light {
  background-color: #e5e5e5;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-25 {
  margin-bottom: 25px;
}

.cursor-pointer {
  cursor: pointer;
}

.main {
  margin: 0 auto;
}

.header-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 27px 0 24px 0;
  justify-content: space-between;
  align-items: center;
}

.filter-btn {
  background-color: #fff;
  border-radius: 5px;
  width: 95px;
  height: 28px;
  border: none;
  color: #333;
  cursor: pointer;
}

.opacity-full {
  opacity: 1;
}

.opacity-low {
  opacity: 0.7;
}

.blue-bg {
  background-color: #3751ff;
}

.orange-gradient {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.silver-bg {
  background-color: silver;
}

.gap-10 {
  gap: 10px;
}

.assignment-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 30px;
}

.assignment-item {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  position: relative;
  gap: 10px;
  margin-bottom: 10px;
}

.assignment-item > * {
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.eyeIcon {
  width: 30px;
  height: auto;
  cursor: pointer;
}

.action-btn {
  border-radius: 5px;
  font-size: 14px;
  height: 28px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.base-btn {
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
  color: #fff;
}

// view question modal styles (eye icon)
.viewQuestionHeader {
  background-color: #642c90;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 5px;
}

.question-img {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-top: 10px;
  border-radius: 5px;
}

.question-img img {
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

//   delete modal style
.cancel-modal {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
  min-width: 500px;
}

.reschedule-modal {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 15px 0;
  min-width: 500px;
}

.publish-assignment {
  min-height: 400px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 400px !important;
}

@media only screen and (max-width: 400px) {
  .publish-assignment {
    white-space: normal;
  }
}

.publish-modal-info {
  color: gray;
  font-style: italic;
}

@media only screen and (max-width: 900px) {
  .cancel-modal,
  .reschedule-modal {
    min-width: 90%;
  }
}

.browser-default {
  border-radius: 5px;
  height: 40px;
  border: 1px solid #e3e3e3;
  // width: 220px;
}

.date-input {
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px !important;
  // width: 220px !important;
  margin-top: 5px;
}

.modal-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.modal-btn {
  width: 100px;
  border: none;
  padding: 10px 0;
  color: #fff;
  border-radius: 4px;
}

.modal-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.yes-btn {
  background-color: #ff6b6b;
}

.publish-modal-button {
  width: 130px;
  height: 40px;
  background: #37841c;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin-right: 30px;
  cursor: pointer;
}

.cancel-publish-btn {
  width: 125px;
  height: 40px;
  border: 1px solid #ff7675;
  border-radius: 5px;
  color: #ff7675;
  line-height: 40px;
  text-transform: uppercase;
  background: white;
  cursor: pointer;
}

@media only screen and (max-width: 950px) {
  .assignment-item > * {
    border: 1px solid #e3e3e36e !important;
    padding: 5px;
    border-radius: 5px;
  }

  .assignment-item {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 730px) {
    .assignment-item > * {
      flex: 1 1 170px;
    }
  }
}

// course-batch filters css
.filter-dropdown {
  display: block;
  width: 200px;
  border-radius: 5px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}

.filter-dropdown:focus {
  border: 1px solid #642c90;
}

.assignment-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.assignment-modal-body {
  height: 420px;
  width: 550px !important;
  /* overflow-x: scroll; */
  /* overflow-y: scroll; */
}

.filterSection {
  width: 265px;
  border-radius: 4px;
  border: 1px solid #e0e4f0;
  /* padding-left: 15px; */
  padding-top: 5px;
  padding-bottom: 35px;
  /* height: 525px; */
}

.scroll-verticle-bar {
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
  margin: 0 10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3f3f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #4787f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f3f3f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f3f3f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(22px);
  background-color: #fcbc5c;
}

.slider.round {
  border-radius: 34px;
}

.increaseHeight {
  height: 105px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
